import { getBooksPk } from "@services/books";
import { putUsers } from "@services/account/user";
import {
    getPageProfile,
    postPageProfile,
    putPageProfile,
    deletePageProfile,
} from "@services/page-profile";
import deepClone from "deep-clone";

const state = {
    settings: [
        { alert: "largeVolume", width: 600, height: 300, posX: 100, posY: 100 },
        { alert: "fastTrade", width: 600, height: 300, posX: 100, posY: 100 },
        {
            alert: "largeExposure",
            width: 600,
            height: 300,
            posX: 100,
            posY: 100,
        },
        {
            alert: "lockingPosition",
            width: 600,
            height: 300,
            posX: 100,
            posY: 100,
        },
        { alert: "mandate", width: 600, height: 300, posX: 100, posY: 100 },
        {
            alert: "weightedVolume",
            width: 600,
            height: 300,
            posX: 100,
            posY: 100,
        },
        { alert: "profitTaker", width: 600, height: 300, posX: 100, posY: 100 },
        {
            alert: "tradeOnCredit",
            width: 600,
            height: 300,
            posX: 100,
            posY: 100,
        },
        { alert: "watchList", width: 600, height: 300, posX: 100, posY: 100 },
        { alert: "dailyWinner", width: 600, height: 300, posX: 100, posY: 100 },
        { alert: "dailyLoser", width: 600, height: 300, posX: 100, posY: 100 },
        {
            alert: "largeExposureVolume",
            width: 600,
            height: 300,
            posX: 100,
            posY: 100,
        },
        {
            alert: "depositWithdrawal",
            width: 600,
            height: 300,
            posX: 100,
            posY: 100,
        },
        {
            alert: "sameDirection",
            width: 600,
            height: 300,
            posX: 100,
            posY: 100,
        },
        { alert: "tick", width: 600, height: 300, posX: 100, posY: 100 },
    ],
    allAlertOptions: {
        fastTrade: { name: "Fast Trade", value: "fastTrade" },
        largeVolume: { name: "Big Lot", value: "largeVolume" },
        largeVolumeUSD: { name: "Large Volume USD", value: "largeVolumeUSD" },
        // weightedVolume: { name: "Weighted Volume", value: "weightedVolume" },
        lockingPosition: { name: "Locking Position", value: "lockingPosition" },
        profitTaker: { name: "Profit Taker", value: "profitTaker" },
        tradeOnCredit: { name: "Trade On Credit", value: "tradeOnCredit" },
        watchList: { name: "Watch List", value: "watchList" },
        dailyWinner: { name: "Daily Winner", value: "dailyWinner" },
        dailyLoser: { name: "Daily Loser", value: "dailyLoser" },
        largeExposureVolume: {
            name: "Large Exposure Volume",
            value: "largeExposureVolume",
        },
        depositWithdrawal: {
            name: "Deposit Withdrawal",
            value: "depositWithdrawal",
        },
        sameDirection: { name: "Same Direction", value: "sameDirection" },
    },
    clickedOutside: false,
    allProfile: [],
    selectedProfile: { id: 0 },
    editingDialog: null,
    updated: false,
    loading: false,
    isBtnDisabled: true,
    lastAddition: null,
    defaultSetting: {
        settings: {
            fastTrade: {
                name: {
                    text: "Name",
                    showing: true,
                },
                volume: {
                    text: "Volume",
                    type: "number",
                    showing: true,
                    min: null,
                    max: null,
                },
                symbol: {
                    text: "Symbol",
                    type: "string",
                    showing: true,
                    filter_term: "",
                },
                duration: {
                    text: "Duration",
                    type: "number",
                    showing: true,
                    min: null,
                    max: null,
                },
                book_a: {
                    text: "Book",
                    showing: true,
                },
            },
            largeVolume: {
                name: {
                    text: "Name",
                    showing: true,
                },
                symbol: {
                    text: "Symbol",
                    type: "string",
                    showing: true,
                    filter_term: "",
                },
                volume: {
                    text: "Volume",
                    type: "number",
                    showing: true,
                    min: null,
                    max: null,
                },
                open_time: {
                    text: "Open Time",
                    type: "time",
                    showing: true,
                    from: "00:00",
                    to: "23:59",
                },
                close_time: {
                    text: "Close Time",
                    type: "time",
                    showing: true,
                    from: "00:00",
                    to: "23:59",
                },
                action: {
                    text: "Action",
                    type: null,
                    showing: true,
                },
                books: {
                    text: "Books",
                    showing: true,
                },
            },
            largeVolumeUSD: {
                name: {
                    text: "Name",
                    showing: true,
                },
                symbol: {
                    text: "Symbol",
                    type: "string",
                    showing: true,
                    filter_term: "",
                },
                volumeUSD: {
                    text: "Volume USD",
                    type: "number",
                    showing: true,
                    min: null,
                    max: null,
                },
                open_time: {
                    text: "Open Time",
                    type: "time",
                    showing: true,
                    from: "00:00",
                    to: "23:59",
                },
                close_time: {
                    text: "Close Time",
                    type: "time",
                    showing: true,
                    from: "00:00",
                    to: "23:59",
                },
                action: {
                    text: "Action",
                    type: null,
                    showing: true,
                },
                books: {
                    text: "Books",
                    showing: true,
                },
            },
            lockingPosition: {
                name: {
                    text: "Name",
                    showing: true,
                },
                symbol: {
                    text: "Symbol",
                    type: "string",
                    showing: true,
                    filter_term: "",
                },
                locking_position: {
                    text: "Locking Position",
                    type: "number",
                    showing: true,
                    min: null,
                    max: null,
                },
                trigger_time: {
                    text: "Trigger Time",
                    type: "time",
                    showing: true,
                    from: "00:00",
                    to: "23:59",
                },
                books: {
                    text: "Books",
                    showing: true,
                },
            },
            profitTaker: {
                name: {
                    text: "Name",
                    showing: true,
                },
                symbol: {
                    text: "Symbol",
                    type: "string",
                    showing: true,
                    filter_term: "",
                },
                profit_closed_eod: {
                    text: "Profit EOD",
                    type: "number",
                    showing: true,
                    min: null,
                    max: null,
                },
                trigger_time: {
                    text: "Trigger Time",
                    type: "time",
                    showing: true,
                    from: "00:00",
                    to: "23:59",
                },
                books: {
                    text: "Book",
                    showing: true,
                },
            },
            tradeOnCredit: {
                name: {
                    text: "Name",
                    showing: true,
                },
                equity: {
                    text: "Equity",
                    type: "number",
                    showing: true,
                    min: null,
                    max: null,
                },
                balance: {
                    text: "Balance",
                    type: "number",
                    showing: true,
                    min: null,
                    max: null,
                },
                credit: {
                    text: "Credit",
                    type: "number",
                    showing: true,
                    min: null,
                    max: null,
                },
                trigger_time: {
                    text: "Trigger Time",
                    type: "time",
                    showing: true,
                    from: "00:00",
                    to: "23:59",
                },
                books: {
                    text: "Book",
                    showing: true,
                },
            },
            watchList: {
                name: {
                    text: "Name",
                    showing: true,
                },
                volume: {
                    text: "Volume",
                    type: "number",
                    showing: true,
                    min: null,
                    max: null,
                },
                symbol: {
                    text: "Symbol",
                    type: "string",
                    showing: true,
                    filter_term: "",
                },
                duration: {
                    text: "Duration",
                    type: "number",
                    showing: true,
                    min: null,
                    max: null,
                },
                open_price: {
                    text: "Open Price",
                    type: "number",
                    showing: true,
                    min: null,
                    max: null,
                },
                close_price: {
                    text: "Close Price",
                    type: "number",
                    showing: true,
                    min: null,
                    max: null,
                },
                books: {
                    text: "Book",
                    showing: true,
                },
            },
            dailyWinner: {
                name: {
                    text: "Name",
                    showing: true,
                },
                closed_profit_eod: {
                    text: "Closed Profit EOD",
                    type: "number",
                    showing: true,
                    min: null,
                    max: null,
                },
                closed_profit: {
                    text: "Closed Profit MT4",
                    type: "number",
                    showing: true,
                    min: null,
                    max: null,
                },
                books: {
                    text: "Books",
                    showing: true,
                },
            },
            dailyLoser: {
                name: {
                    text: "Name",
                    showing: true,
                },
                closed_profit_eod: {
                    text: "Closed Profit EOD",
                    type: "number",
                    showing: true,
                    min: null,
                    max: null,
                },
                closed_profit: {
                    text: "Closed Profit MT4",
                    type: "number",
                    showing: true,
                    min: null,
                    max: null,
                },
                books: {
                    text: "Books",
                    showing: true,
                },
            },
            largeExposureVolume: {
                name: {
                    text: "Name",
                    showing: true,
                },
                symbol: {
                    text: "Symbol",
                    type: "string",
                    showing: true,
                    filter_term: "",
                },
                exposureVolume: {
                    text: "Volume",
                    type: "number",
                    showing: true,
                    min: null,
                    max: null,
                },
                books: {
                    text: "Books",
                    showing: true,
                },
            },
            depositWithdrawal: {
                name: {
                    text: "Name",
                    showing: true,
                },
                amount: {
                    text: "Amount",
                    type: "number",
                    showing: true,
                    min: null,
                    max: null,
                },
                trigger_time: {
                    text: "Trigger Time",
                    type: "time",
                    showing: true,
                    from: "00:00",
                    to: "23:59",
                },
                action: {
                    text: "Action",
                    showing: true,
                },
                book_name: {
                    text: "Book",
                    showing: true,
                },
            },
            sameDirection: {
                name: {
                    text: "Name",
                    showing: true,
                },
                symbol: {
                    text: "Symbol",
                    type: "string",
                    showing: true,
                    filter_term: "",
                },
                book: {
                    text: "Book",
                    showing: true,
                },
                total_volume: {
                    text: "Volume",
                    type: "number",
                    showing: true,
                    min: null,
                    max: null,
                },
                order: {
                    text: "Order",
                    type: "string",
                    showing: true,
                    filter_term: "",
                },
                trigger_time: {
                    text: "Trigger Time",
                    type: "time",
                    showing: true,
                    from: "00:00",
                    to: "23:59",
                },
                event: {
                    text: "Event",
                    showing: true,
                },
            },
        },
        unselectedBooks: [],
        unselected: [],
    },
    books: [],
    selectedPeriod: 10,
    periodOptions: [0, 10, 20, 60, 120],
    alertTutorial: false,
    extraIBHeaders: [
        {
            text: "IB",
            value: "partner_id",
            align: "left",
            width: 80,
        },
        {
            text: "Back Office",
            value: "back_office_id",
            align: "left",
            width: 100,
        },
        {
            text: "Customer",
            value: "account_id",
            align: "left",
            width: 100,
        },
    ],
};

const actions = {
    getProfileAction({ commit }, data) {
        commit("UPDATE_BTN_STATUS", true);
        getPageProfile(data).then((res) => {
            if (res.data.length == 0) {
                commit("UPDATE_ALL_PROFILE", [
                    {
                        id: 0,
                        text: "default",
                        unselected: state.defaultSetting.unselected,
                        settings: state.defaultSetting.settings,
                        unselectedBooks: state.defaultSetting.unselectedBooks,
                        positions: state.settings,
                    },
                ]);
                commit("UPDATE_SELECTED_PROFILE", {
                    id: 0,
                    text: "default",
                    unselected: state.defaultSetting.unselected,
                    settings: state.defaultSetting.settings,
                    unselectedBooks: state.defaultSetting.unselectedBooks,
                    positions: state.settings,
                });
            } else {
                const returnedProfileList = res.data.map((profile) => {
                    return {
                        id: profile.id,
                        text: profile.profile_name,
                        settings: JSON.parse(profile.value).settings,
                        positions:
                            JSON.parse(profile.value).positions == []
                                ? state.settings
                                : JSON.parse(profile.value).positions,
                        unselected: JSON.parse(profile.value).unselected,
                        unselectedBooks: JSON.parse(profile.value)
                            .unselectedBooks,
                    };
                });
                commit("UPDATE_ALL_PROFILE", returnedProfileList);

                const alertId = parseInt(localStorage.getItem("alertId"));
                if (alertId) {
                    if (
                        returnedProfileList.find((item) => item.id === alertId)
                    ) {
                        commit(
                            "UPDATE_SELECTED_PROFILE",
                            returnedProfileList.find(
                                (item) => item.id === alertId
                            )
                        );
                    } else {
                        commit(
                            "UPDATE_SELECTED_PROFILE",
                            returnedProfileList[0]
                        );
                    }
                } else {
                    commit("UPDATE_SELECTED_PROFILE", returnedProfileList[0]);
                }
            }
        });
    },
    putProfileAction({ commit }, data) {
        commit("UPDATE_LOADING", true);
        putPageProfile(data).then(() => {
            commit("CHANGE_UPDATE_STATUS", true);
            commit("UPDATE_LOADING", false);
        });
    },
    postProfileAction({ commit }, data) {
        commit("UPDATE_LOADING", true);
        postPageProfile(data).then(() => {
            commit("UPDATE_LAST_ADDITION", data.profile_name);
            commit("CHANGE_UPDATE_STATUS", true);
            commit("UPDATE_LOADING", false);
        });
    },
    deletePageProfileAction({ commit, state }, data) {
        deletePageProfile(data).then((res) => {
            if (res.status === 200) {
                const remaining = state.allProfile.filter(
                    (profile) => profile.id != data.id
                );
                if (remaining.length > 0) {
                    commit("UPDATE_ALL_PROFILE", remaining);
                    commit("UPDATE_SELECTED_PROFILE", remaining[0]);
                } else {
                    commit("UPDATE_ALL_PROFILE", [
                        {
                            id: 0,
                            text: "default",
                            unselected: [],
                            unselectedBooks: [],
                            settings: state.defaultSetting.settings,
                            positions: state.settings,
                        },
                    ]);
                    commit("UPDATE_SELECTED_PROFILE", {
                        id: 0,
                        text: "default",
                        unselected: state.defaultSetting.unselected,
                        settings: state.defaultSetting.settings,
                        unselectedBooks: state.defaultSetting.unselectedBooks,
                        positions: state.settings,
                    });
                }
            }
        });
    },
    getBooksPkAction({ commit }) {
        getBooksPk().then((res) => {
            let bookOptions = [];
            res.data.map((item) => {
                bookOptions.push(item.book_name);
                if (item.children) {
                    item.children.map((child) =>
                        bookOptions.push(child.book_name)
                    );
                }
            });
            commit("UPDATE_BOOKS", bookOptions);
        });
    },
    putUserProfileAction(_, params) {
        putUsers(params).then((res) => {});
    },
    updateAlertTutorialAction({ state }, data) {
        state.alertTutorial = data;
    },
};

const mutations = {
    UPDATE_ALL_ALERT_OPTIONS(state, data) {
        state.allAlertOptions = data;
    },
    UPDATE_SELECTED_PROFILE(state, data) {
        if (localStorage.getItem("alertId") !== data.id) {
            localStorage.setItem("alertId", data.id);
        }
        state.selectedProfile = deepClone(data);
    },
    UPDATE_ALL_PROFILE(state, data) {
        localStorage.setItem("alertProfiles", JSON.stringify(data));
        state.allProfile = data;
        state.isBtnDisabled = false;
    },
    CHANGE_UPDATE_STATUS(state, data) {
        state.updated = data;
    },
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_BTN_STATUS(state, data) {
        state.isBtnDisabled = data;
    },
    UPDATE_CLICKED_OUTSIDE(state, data) {
        state.clickedOutside = data;
    },
    UPDATE_LAST_ADDITION(state, data) {
        state.lastAddition = data;
    },
    UPDATE_BOOKS(state, data) {
        state.books = data;
    },
    UPDATE_REFRESH_PERIOD(state, data) {
        state.selectedPeriod = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
