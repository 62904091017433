import {
    getCopyTradeConfig,
    updateCopyTradeConfig,
    createCopyTradeConfig,
    deleteCopyTradeConfig,
} from "@services/copy-trade/config";

const state = {
    loading: false,
    copyTradeHeader: [
        {
            text: "ID",
            value: "id",
            align: "left",
            width: 60,
            class: ["fixCol_first", "header"],
        },
        {
            text: "Server",
            value: "server_id",
            align: "left",
            width: 100,
            class: ["fixCol_second", "header"],
        },
        {
            text: "Source Login",
            value: "SourceLogin",
            align: "left",
            width: 120,
            class: ["fixCol_third", "header"],
        },
        {
            text: "Target Login",
            value: "TargetLogin",
            align: "left",
            width: 100,
            class: ["fixCol_forth", "header"],
        },
        {
            text: "Verify Time Range",
            value: "VerifyTimeRange",
            align: "left",
            width: 150,
        },
        {
            text: "Partial Follower Time Range",
            value: "PartialFollowerTimeRange",
            align: "left",
            width: 200,
        },
        {
            text: "Mobile Ratio",
            value: "MobileRatio",
            align: "left",
            width: 100,
        },
        {
            text: "Client Ratio",
            value: "ClientRatio",
            align: "left",
            width: 100,
        },
        {
            text: "Expert Ratio",
            value: "ExpertRatio",
            align: "left",
            width: 100,
        },
        { text: "TP", value: "TP", align: "left", width: 100 },
        { text: "SL", value: "SL", align: "left", width: 100 },
        { text: "Is Reversed", value: "IsReverse", align: "left", width: 100 },
        {
            text: "Is Follow Pending Order",
            value: "IsFollowPendingOrder",
            align: "left",
            width: 180,
        },
        {
            text: "Pending Ratios",
            value: "PendingRatios",
            align: "left",
            width: 120,
        },
        {
            text: "Comment Prefix",
            value: "CommentPrefix",
            align: "left",
            width: 120,
        },
        {
            text: "Symbol Suffix",
            value: "SymbolSuffix",
            align: "left",
            width: 120,
        },
        {
            text: "Min Lots Trigger",
            value: "MinLotsTrigger",
            align: "left",
            width: 140,
        },
        {
            text: "Stop Or Limit",
            value: "StopOrLimit",
            align: "left",
            width: 120,
        },
        {
            text: "Pending Points",
            value: "PendingPoints",
            align: "left",
            width: 120,
        },
        {
            text: "Actions",
            value: "actions",
            align: "left",
            width: 100,
            class: ["fixCol_last", "header"],
        },
    ],
    copyTradeConfig: [],
    updated: false,
    csvFields: {
        ID: "id",
        Server: "server_id",
        "Source Login": "SourceLogin",
        "Target Login": "TargetLogin",
        "Verify Time Range": "VerifyTimeRange",
        "Partial Follower Time Range": "PartialFollowerTimeRange",
        "Mobile Ratio": "MobileRatio",
        "Client Ratio": "ClientRatio",
        "Expert Ratio": "ExpertRatio",
        TP: "TP",
        SL: "SL",
        "Is Reversed": "IsReverse",
        "Is Follow Pending Order": "IsFollowPendingOrder",
        "Pending Ratios": "PendingRatios",
        "Comment Prefix": "CommentPrefix",
        "Symbol Suffix": "SymbolSuffix",
        "Min Lots Trigger": "MinLotsTrigger",
        "Stop Or Limit": "StopOrLimit",
        "Pending Points": "PendingPoints",
    },
};

const actions = {
    getCopyTradeConfigAction({ commit }) {
        // commit("UPDATE_LOADING", true);
        // getCopyTradeConfig()
        //     .then((res) => {
        //         commit("UPDATE_COPY_TRADE_CONFIG", res?.data || []);
        //         let snackbar = {
        //             message: "Reading Config Successfully",
        //             color: "green",
        //             btnName: "Close",
        //             status: true,
        //             timeout: 2000,
        //         };
        //         commit("UPDATE_SNACKBAR", snackbar, { root: true });
        //         commit("UPDATE_LOADING", false);
        //     })
        //     .catch(() => {
        //         let snackbar = {
        //             message: "No Config Found",
        //             color: "red",
        //             btnName: "Close",
        //             status: true,
        //             timeout: 2000,
        //         };
        //         commit("UPDATE_SNACKBAR", snackbar, { root: true });
        //         commit("UPDATE_LOADING", false);
        //     });
    },
    addCopyTradeConfigAction({ commit }, params) {
        commit("UPDATE_LOADING", true);
        createCopyTradeConfig(params)
            .then((res) => {
                let snackbar = {
                    message: res.data.msg,
                    color: "green",
                    btnName: "Close",
                    status: true,
                    timeout: 2000,
                };
                commit("UPDATE_SNACKBAR", snackbar, { root: true });
                commit("UPDATE_STATUS", true);
                commit("UPDATE_LOADING", false);
            })
            .catch(() => {
                let snackbar = {
                    message: "Something went wrong, try again later",
                    color: "red",
                    btnName: "Close",
                    status: true,
                    timeout: 2000,
                };
                commit("UPDATE_SNACKBAR", snackbar, { root: true });
                commit("UPDATE_LOADING", false);
            });
    },
    deleteCopyTradeConfigAction({ commit }, params) {
        commit("UPDATE_LOADING", true);
        deleteCopyTradeConfig(params)
            .then((res) => {
                let snackbar = {
                    message: res.data.msg,
                    color: "green",
                    btnName: "Close",
                    status: true,
                    timeout: 2000,
                };
                commit("UPDATE_SNACKBAR", snackbar, { root: true });
                commit("UPDATE_STATUS", true);
                commit("UPDATE_LOADING", false);
            })
            .catch(() => {
                let snackbar = {
                    message: "Something went wrong, try again later",
                    color: "red",
                    btnName: "Close",
                    status: true,
                    timeout: 2000,
                };
                commit("UPDATE_SNACKBAR", snackbar, { root: true });
                commit("UPDATE_LOADING", false);
            });
    },
    updateCopyTradeConfigAction({ commit }, params) {
        commit("UPDATE_LOADING", true);
        updateCopyTradeConfig(params)
            .then((res) => {
                let snackbar = {
                    message: res.data.msg,
                    color: "green",
                    btnName: "Close",
                    status: true,
                    timeout: 2000,
                };
                commit("UPDATE_SNACKBAR", snackbar, { root: true });
                commit("UPDATE_STATUS", true);
                commit("UPDATE_LOADING", false);
            })
            .catch(() => {
                let snackbar = {
                    message: "Something went wrong, try again later",
                    color: "red",
                    btnName: "Close",
                    status: true,
                    timeout: 2000,
                };
                commit("UPDATE_SNACKBAR", snackbar, { root: true });
                commit("UPDATE_LOADING", false);
            });
    },
};

const mutations = {
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_STATUS(state, data) {
        state.updated = data;
    },
    UPDATE_COPY_TRADE_CONFIG(state, list) {
        state.copyTradeConfig = list;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
